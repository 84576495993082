angular.module('app').controller('DeliveryOrderController', DeliveryOrderController);

function DeliveryOrderController($window, $scope, $http, HttpService, ArticleService) {

    $scope.article_id = null;
    $scope.article = null;
    $scope.shoppingCartDelivery = {};
    $scope.item={};
    $scope.discounts={};
    $scope.pack = 0;
    $scope.articles = ArticleService.articles;
    $scope.Math = window.Math;

    $scope.loadArticles = function (deliveryId) {
        ArticleService.getArticles(deliveryId);
    };

    $scope.submit = function (createNew = false) {
        $http.post(orderPostUrl, {
            item: $scope.item,
            shoppingCartDelivery: HttpService.toSnakeCaseProperties($scope.shoppingCartDelivery)
        }).then(
            function (data) {
                var url = data.data;
                if (createNew) {
                    url = url + '/uj?userId=' + encodeURIComponent($scope.item.user_id) + '&orderDate=' + encodeURIComponent($scope.item.order_date);
                }
                $window.location.href = url;
            },
            function (data) {
                console.log(data);
                if (typeof data === 'string' || data.data && data.data.substr(0,5) == '<!DOC') {
                    $('#messages').html(data.data);
                    $('#messages').show();
                }
                else if (data.data) {
                    let messages = '';
                    if (data.data instanceof Array) {
                        for (let key in data.data) {
                            let message = data.data[key][0];
                            messages += message + '<br/>';
                        }
                        $('#messages').html(messages);
                    }
                    else {
                        $('#messages').html(data.data);
                    }
                    $('#messages').show();
                }
            });
    };

    $scope.articleIdChanged = function () {
        for (let i = 0; i < articles.length; i++) {
            let article = articles[i];
            if (article.id == $scope.item.article_id) {
                $scope.article = article;
                $scope.maxPacks = article.free % article.minimum_order_quantity;
                recalcValues($scope.pack);
            }
        }
    };

    $scope.packChanged = function () {
        recalcValues($scope.pack);
    };

    let recalcValues = function (value) {
        if ($scope.article) {
            $scope.item.amount = value * $scope.article.minimum_order_quantity;
        }
        else {
            $scope.item.amount = 0;
        }
    };

    $scope.setArticleId = function (value) {
        $scope.item.article_id = value;
        $scope.articleIdChanged();
    }

    $scope.setAmount = function (value) {
        this.originalAmount = value;
        this.item.amount = value;
        if ($scope.article) {
            let packs = Math.floor($scope.item.amount / $scope.article.minimum_order_quantity);
            if (packs !== $scope.pack) {
                $scope.pack = packs;
            }
        }
        else {
            $scope.pack = 0;
        }
    }

    $scope.deleteDeliveryOrder = function (id) {
        if (confirm('Biztosan törlöd ezt a tételt?')) {
            var url = deliveryOrderDeleteURL + '/' + id;
            $http.delete(url)
                .then(function successCallback(response) {
                    $('#row-' + id).transition('fade', 250, function () {
                        $('#row-' + id).remove();
                    });

                }, function errorCallback(response) {
                    alert("Hiba történt törlés közben.");
                });
        }
    };

    $scope.closeDelivery = function (id) {
        if (confirm('Biztosan lezárod ezt a szállítási időpontot?')) {
            $("#closeLoader").addClass("active");
            var url = deliveryCloseURL;
            $http.post(url)
                .then(function successCallback(response) {
                    $("#closeLoader").removeClass("active");
                    $("#closer").hide();
                }, function errorCallback(response) {
                    alert("Hiba történt zárás közben.");
                    $("#closeLoader").removeClass("active");
                });
        }
    }

    $scope.saveDiscounts = function () {
        $http.post(discountSaveUrl, {discounts: $scope.discounts})
            .then(function successCallback(response) {
                $window.location.reload(true);
            }, function errorCallback(response) {
                alert("Hiba történt törlés közben.");
            });

    }

    $scope.calculatePrice = function () {
        const article = articles.find(articleItem => articleItem.id === $scope.item.article_id);

        if (!article) {
            return;
        }

        $scope.item.unit_price = $scope.item.amount >= article.amount_per_unit ? article.batch_price : article.unit_price;
        $scope.item.price = $scope.item.unit_price * $scope.item.amount;
    }

    $scope.calculatePriceFromUnitPrice = function () {
        $scope.item.price = $scope.item.unit_price * $scope.item.amount;
    }
}
